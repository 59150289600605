import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import './App.css';
import Screen from './components/Screen';
import Fader from './components/Fader';
import Login from './components/Login';
import * as smoothscroll from 'smoothscroll-polyfill';
import { useSelector, useDispatch } from 'react-redux';
import { stopGame, selectGameState, selectGameURL, selectAuthenticating } from './store/screenSlice';

export default function App() {
  
  const dispatch = useDispatch();
  // disable safari bounce
  document.ontouchmove = function (event) {
    event.preventDefault();
  };

  // mobile viewport fix
  const setViewport = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  window.onresize = function () {
    setViewport();
  };
  useLayoutEffect(() => {
    smoothscroll.polyfill();
    setViewport();
  });

  const [startApp, setStartApp] = useState(false);
  const gameState = useSelector(selectGameState);
  const gameURL = useSelector(selectGameURL); 
  const isAutenticating = useSelector(selectAuthenticating);

  useEffect(() => {
    if(!isAutenticating) {
        const videoElement = videoRef.current;
        if (videoElement && gameURL) {
          /*if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          }*/
        }
      }
  }, [gameURL, isAutenticating]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="App">

      {startApp ? (
        <>
        { !gameState && !isAutenticating ? ( <>{<Fader></Fader>}<Screen/></> ) : (
          <>
            { isAutenticating ? ( <Login></Login> ) : (
                <div className="holderGame">
                 <div ref={containerRef} className="containerVideo">
                    <video ref={videoRef} src={gameURL} className="videoHTML" autoPlay controls/>
                    <button className="backButton" onClick={() => dispatch(stopGame())}>
                      <img src={process.env.PUBLIC_URL + '/images/close.png'} alt="Close"></img>
                    </button>
                  </div> 
                </div> 
            )}
          </>
          )
        }
        </>
      ) : (
        <div className="homeDiv">
          <div>
            <img src={process.env.PUBLIC_URL + '/images/logo.png'} className="logo" alt="logo" onClick={() => setStartApp(true)}></img>
          </div>
          <div>
            <img src={process.env.PUBLIC_URL + '/images/slogan.png'} className="slogan" alt="slogan" onClick={() => setStartApp(true)}></img>
          </div>
          <div>
            <img src={process.env.PUBLIC_URL + '/images/home_button.png'} className="homeButton" alt="button" onClick={() => setStartApp(true)}></img>
          </div>
        </div>
      )}
    </div>
  );
}


